.service-page {
  display: flex;
  width: 100%;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content {
  flex: 1;
  padding: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: min-content;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.status-box, .device-name, .details, .component-status {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
}

.status-box {
  grid-column: 1 / 2;
  align-items: center;
  display: flex;
}

.device-name {
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.device-details {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.device-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
}

.device-detail span.value {
  font-weight: normal;
  margin-top: 5px;
}

.status-box h2 {
  margin: 0;
}

.status-icon {
  color: #34CE74;
  font-size: 24px; /* Adjust the size as needed */
  margin-left: 5px; /* Add some space between the text and the icon */
}

.details {
  grid-column: 1 / 2;
  align-self: start; /* Aligns the details box at the start */
}

.details-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail-button {
  padding: 20px 20px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: black;
}

.detail-button:hover {
  background-color: #e0e0e0;
}

.detail-button.active {
  background-color: #FF5800;
  color: white;
}

.logs-section {
  grid-column: 1 / 2;
  margin-top: 20px; /* Adds a gap above the log a fault section */
}

.parameters-and-logs {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}

.component-test {
  margin-top:20px;
  margin-bottom:20px;
}
.component-status {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add spacing between rows */
}

.component-status-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.component-status-left,
.component-status-right {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.vertical-line::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1px;
  background-color: #ddd;
  transform: translateX(-50%);
}

.component-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px; /* Add spacing between each component item */
}

.component-value {
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none;
  text-align: right;
  width: 60%;
}

.lock-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  color: #000;
  font-size: 20px;
}

.lock-icon.unlocked {
  color: #FF5800;
}

.logs {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
}

.logs h3 {
  margin-top: 0;
  padding-bottom: 5px;
  border-bottom: 2px solid #ddd;
}

.logs form {
  display: flex;
  flex-direction: column;
}

.logs label {
  margin-bottom: 10px;
}

.logs textarea {
  width: calc(100% - 20px); /* Adjust the width to account for padding */
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
  resize: none;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.submit-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #FF5800;
}


.component-test h3 {
  margin-top: 0;
  padding-bottom: 5px;
  border-bottom: 2px solid #ddd;
  margin-bottom:0px;
}


h3 {
  margin-top: 0;
  padding-bottom: 5px;
  border-bottom: 2px solid #ddd;
}


.changelog h4 {
  margin: 0 0 10px;
}

.changelog ul {
  list-style-type: disc;
  padding-left: 20px;
}

.changelog ul ul {
  list-style-type: circle;
  padding-left: 20px;
}

.output-box {
  margin-bottom: 20px;
}

.output-box h4 {
  margin: 0 0 10px;
}

.output-box textarea {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  resize: none;
  box-sizing: border-box; /* Add this line */
}

.component-status-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px; /* Add spacing between rows */
}

.component-status-table th,
.component-status-table td {
  padding: 8px;
}

.component-status-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.component-status-table tr {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.component-status-table tr:hover {
  background-color: #f0f0f0;
}



.usage-bar-container {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Add margin to create a gap */
}

.usage-bar-background {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 8px;
  position: relative;
  margin-right: 5px;
}

.usage-bar {
  height: 100%;
  background-color: #FF4E00;
  border-radius: 8px;
}

.usage-percentage {
  font-size: 0.8em;
  white-space: nowrap;
  color: #FF4E00;
  font-weight: bold;
}

.details-column {
  white-space: nowrap;
  width:20%;
}

.usage-column {
  width: 60%;
}

.component-column {
  width:20%;
}

.component-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  display: block;
  margin: 0 auto;
}


.service-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 100%;
  z-index: 1000;
  margin:0 auto;
  text-align: center;
}

.service-button-group {
  display: flex;
  gap: 10px;
  margin: 0 auto;
  justify-content: center; /* Add this line */
}

.recommended-actions {
  text-align: center;
  width:70%;
  margin:0 auto;
}

.usage-status {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width:40%;
  margin:0 auto;
}

.usage, .remaining {
  text-align: center;
}

.label {
  display: block;
  font-weight: bold;
}

.service-value {
  font-size: 1.5em;
  color: #FF4E00;
}

.single-reset-button {
  background: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 8px;
  width:20%;
  margin:0 auto;
  margin-bottom:10px;
}


