.verify-2fa-page {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    text-align: center;
    margin: 0 auto;
  }

  .setup-2fa-page {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    text-align: center;
    margin: 0 auto;
  }

  .qr-code {
    margin-bottom: 20px;
  }
  
  .logo {
    display: block;
    margin: 0 auto 20px;
    max-width: 200px;
  }
  
  h1 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  
  p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #555;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .form-group input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .remember-me {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .remember-me input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .form-actions {
    margin-top: 20px;
  }
  
  .verify-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: #000;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  
  .verify-button:hover {
    background-color: #ff4e00;
  }
  
  .error {
    color: red;
    margin-top: 10px;
    font-weight:bold;
  }
  