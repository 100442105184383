/* UserSettings.css */
.user-settings-page {
  display: flex;
  width: 100%;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content {
  flex: 1;
  padding: 20px;
}

.view-user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.content-title {
  display: flex;
  align-items: center;
}

.content-icon {
  margin-right: 10px;
}

.back-button {
  background-color: #000;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #333;
}

.view-user-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-details {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-image {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.user-info {
  flex: 1;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.info-item {
  flex: 1;
  margin-right: 20px;
}

.info-item label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}


.save-button-container {
  text-align: right;
}

