body, html, #root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  overflow:hidden;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow:hidden;
  
}

h2 {
	font-size:30px;
	margin: 0px;
}

.form-control {
width:100% !important;

}


.submit-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.single-reset-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.reset-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}


.lock-icon {
  cursor: not-allowed;
  color: grey;
}

.lock-icon.unlocked {
  cursor: pointer;
  color: black;
}

.lock-icon.unlocked:hover {
  color: #ff4e00;
  cursor: pointer;
}

.lock-icon.orange {
  color: #ff4e00;
  cursor: pointer;
}

.parameter-select.unlocked,
.feature-select.unlocked {
  border: 1px solid #ccc;
}

.parameter-select:disabled,
.feature-select:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.connectionbutton {
  cursor: pointer;
}

.connectionbutton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
