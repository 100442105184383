/* ViewDevice.css */
.connected-device-page {
  display: flex;
  width: 100%;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content {
  flex: 1;
  padding: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  grid-template-rows: auto auto;
  margin-bottom: 20px;
}

.connectionbutton {
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 150px;  
}

.connectionbutton:hover {
  background-color: #ff4e00;
}

.status-box, .device-name, .details, .parameters, .logs, .authorized-users {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px #ccc;
}

.details {
  grid-column: 1 / 2;
}

.authorized-users {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.View-parameters-and-logs {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.details h3, .parameters h3, .features h3, .logs h3, .authorized-users h3 {
  margin-top: 0;
  padding-bottom: 5px;
  border-bottom: 2px solid #ddd;
}

.details-info, .parameters-info, .features-info, .authorized-users-info {
  display: flex;
  flex-direction: column;
}

.details-info span, .parameters-info span, .features-info span {
  display: block;
  margin-bottom: 10px;
}

.detail-item, .parameter-item, .feature-item, .user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.detail-label, .parameter-label, .feature-label {
  font-weight: bold;
  flex: 1;
}

.detail-value, .parameter-value, .feature-value, .parameter-select, .feature-select, .user-value {
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px; /* Set a consistent width */
  border: none;
  text-align: right; /* Right-align the text */
  flex: 2;
}

.parameter-select, .feature-select {
  cursor: not-allowed;
  padding: 5px;
  text-align-last: right; /* Right-align the selected text */
}

.parameter-select.unlocked, 
.feature-select.unlocked, 
.detail-value.unlocked {
  cursor: pointer;
  background-color: #fff;
}

.parameter-select option, .feature-select option {
  text-align: right; /* Right-align the options */
  padding-right: 10px; /* Add padding to the right */
}

.lock-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  color: #000;
  font-size: 20px;
}

.lock-icon.unlocked {
  color: #FF5800;
}

.delete-icon, .add-icon {
  cursor: pointer;
  color: #000;
  font-size: 20px;
  margin-left: 10px;
}

.logs {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px #ccc;
}

.logs-table {
  width: 100%;
  border-collapse: collapse;
}

.logs-table th, .logs-table td {
  padding: 10px;
  text-align: left;
}

.logs-table th {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0px;
}

.parameters-info {
  margin-bottom: 30px;
}

.user-suggestions li {
  padding: 8px;
  cursor: pointer;
}

.user-suggestions li:hover {
  background-color: #f0f0f0;
}

.user-row {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
}

.user-row:hover {
  background-color: #f0f0f0;
}

.user-label {
  flex-grow: 1;
  text-align: left; /* Left-align the user labels */
}

.new-user-item {
  position: relative;
  margin-top: 10px;
}

.user-search-value {
  width: 100%; /* Make the input full width */
  padding: 8px;
  box-sizing: border-box;
  text-align:left;
}

.user-suggestions {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;
}

.user-suggestion {
  padding: 8px;
  cursor: pointer;
  text-align: left; /* Left-align the suggestions */
}

.user-suggestion:hover {
  background-color: #f0f0f0;
}

.delete-device-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
}

.delete-device-button:hover {
  background-color: #c82333;
}

.button-group {
  display: flex;
  gap: 10px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 100%;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #000;
  color: white;

}

button:hover {
  background-color: #ff4e00;
}

.log-content-textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
}