.upload-log-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.upload-log-file {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: center;
}

h1 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
}

.connection-status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.logconnectionbutton {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 20px; /* Increased margin-right for more space */
}

.logconnectionbutton.connected {
  background-color: #000;
  color: white;
}

.logconnectionbutton.disconnected {
  background-color: #000;
  color: white;
}

.status-text {
  font-size: 16px;
  color: black;
}

.status-text .connected {
  color: #4caf50;
}

.status-text .disconnected {
  color: #f44336;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #ddd;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.upload-button {
  background-color: #000;
  color: white;
  margin-top: 20px;
}

.upload-button:hover {
  background-color: #ff4e00;
}

.return-button {
  background-color: #000;
  color: white;
  cursor: pointer;
  padding: 10px 30px;
  font-weight: bold;
  margin-top: 20px;
}

.return-button:hover {
  background-color: #ff4e00;
}

.logo {
  display: block;
  margin: 0 auto 20px;
  width: 150px;
}

.divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
  margin: 0 10px;
}

.divider span {
  color: #555;
}
