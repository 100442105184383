.serial-communicator-page {
  display: flex;
  width: 100%;
  height: 100vh;
}

.content {
  flex: 1;
  width: 100%;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 21px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px #ccc;
  margin-bottom: 20px;
}

.content-title {
  display: flex;
  align-items: center;
}
  
  .content-icon {
    font-size: 24px;
    margin-right: 10px;
    color: #333;
  }
  
  .connection-controls {
    display: flex;
  }
  
  .connect-btn, .disconnect-btn, .command-btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
  }
  
  .connect-btn {
    background-color: #000;
    color: white;
  }
  
  .connect-btn:hover {
    background-color: #FF4E00;
  }
  
  .disconnect-btn {
    background-color: #D62828;
    color: white;
  }
  
  .disconnect-btn:hover {
    background-color: #9E1A1A;
  }
  
  .command-btn {
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .command-btn:hover {
    background-color: #FF4E00;
  }
  
  .device-info-section, .command-section, .output-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px #ccc;
    margin-bottom: 20px;
  }
  
  .command-section {
    margin-bottom: 1rem;
  }
  
  .commands-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  
  .primary-commands {
    display: flex;
    gap: 0.5rem;
  }
  
  .command-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .command-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .command-btn.primary {
    background-color: #000;
    color: white;
  }
  
  .command-btn.primary:hover {
    background-color: #FF4E00;
  }
  
  .command-btn.primary.active {
    background-color: #D62828;
  }
  
  .command-btn.secondary {
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ddd;
  }
  
  .command-btn.secondary:hover {
    background-color: #eee;
  }
  
  .device-controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  
  .control-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .control-group {
    display: flex;
    gap: 0.5rem;
  }
  
  .target-select {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #333;
    background-color: #fff;
  }
  
  .target-select:focus {
    outline: none;
    border-color: #999;
  }
  
  @media (max-width: 768px) {
    .device-controls {
      grid-template-columns: 1fr;
    }
    
    .control-group {
      flex-direction: column;
    }
    
    .command-btn {
      width: 100%;
    }
  }
  
  .detail-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .detail-item h5 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .detail-item ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .command-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .target-commands {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
  }
  
  .reset-device, .test-device {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
  }
  
  select {
    padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-right: 10px;
    min-width: 150px;
  }
  
  .output-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .output-title {
    display: flex;
    align-items: center;
  }
  
  .output-icon {
    font-size: 20px;
    margin-right: 10px;
    color: #333;
  }
  
  .clear-output-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .clear-output-btn svg {
    margin-right: 5px;
  }
  
  .clear-output-btn:hover {
    background-color: #d32f2f;
  }
  
  .output-textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
    font-family: monospace;
  }
  
  .device-details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem;
  }
  
  .detail-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .detail-card.full-width {
    grid-column: 1 / -1;
  }
  
  .card-header {
    background: #f5f5f5;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #eee;
  }
  
  .card-header h4 {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
    color: #666;
  }
  
  .card-content {
    padding: 0.75rem;
  }
  
  .card-content.compact {
    padding: 0.5rem;
  }
  
  .detail-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0;
    font-size: 0.9rem;
  }
  
  .label {
    color: #666;
  }
  
  .value {
    font-weight: 500;
    color: #333;
  }
  
  .components-overview {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .main-components {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #eee;
  }
  
  .component-lists {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  
  .value-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .value-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5f5f5;
    padding: 0.25rem;
    border-radius: 4px;
    text-align: center;
  }
  
  .value-item small {
    font-size: 0.7rem;
    color: #666;
  }
  
  .value-item span {
    font-weight: 500;
    font-size: 0.9rem;
  }
  
  h5 {
    margin: 0;
    font-size: 0.8rem;
    color: #666;
    font-weight: 600;
  }
  
  @media (max-width: 768px) {
    .device-details-grid {
      grid-template-columns: 1fr;
    }
    
    .main-components {
      grid-template-columns: 1fr;
    }
    
    .component-lists {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
  
  .components-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }
  
  .component-column h5 {
    margin: 0 0 0.5rem 0;
    color: #666;
    font-size: 0.9rem;
    font-weight: 600;
  }
  
  .output-content {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 300px;
    overflow-y: auto;
    padding: 10px;
    font-family: monospace;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-height: 400px;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
  
  .output-item {
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 4px;
  }
  
  .output-item.sent {
    background-color: #e6f3ff;
    color: #0066cc;
  }
  
  .output-item.received {
    background-color: #e6ffe6;
    color: #006600;
  }
  
  .output-item.error {
    background-color: #ffe6e6;
    color: #cc0000;
  }
  
  .chart-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px #ccc;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    height: 700px;
  }
  
  .chart-container {
    height: 600px;
    width: 100%;
  }
  
  @media (max-width: 1240px) {
    .content {
      padding: 20px;
      margin: 0 20px;
    }
  }
  
  @media (max-width: 768px) {
    .content {
      padding: 10px;
      margin: 0 10px;
    }
    
    .chart-section {
      padding: 10px;
    }
    
    .target-commands {
      grid-template-columns: 1fr;
    }
  }
  
  .commands-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 1rem;
  }
  
  .command-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow: hidden;
  }
  
  .command-card .card-header {
    background: #f5f5f5;
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
  }
  
  .command-card .card-header h4 {
    margin: 0;
    color: #333;
  }
  
  .command-card .card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .control-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .target-select, .log-level-select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
  }
  
  .stream-controls {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .stream-settings {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .stream-settings input {
    width: 80px;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .command-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .command-btn.primary {
    background: #000;
    color: white;
  }
  
  .command-btn.secondary {
    background: #6c757d;
    color: white;
  }
  
  .command-btn.warning {
    background: #dc3545;
    color: white;
  }
  
  .command-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .logging-controls {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  