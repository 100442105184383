.view-user-page {
    display: flex;
    width: 100%;
    height: 100vh;
  }
  
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  .view-user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 21px 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px #ccc;
    margin-bottom: 20px;
  }
  
  .content-title {
    display: flex;
    align-items: center;
  }
  
  .content-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .add-user-button {
    background-color: #000;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 194px;
  }
  
  .view-user-content {
    position: relative;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .lock-container {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .lock-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .user-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .user-image img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  
  .info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .info-item {
    display: flex;
    flex-direction: column;
    width: 48%;
  }
  
  .info-item label {
    font-weight: bold;
  }
  
  .info-item input, select {
    
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }


  .save-button {
    background-color: #000;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
  }
  
  .device-list {
    width: 80%;
    margin: 40px auto 20px auto;
  }
  
  .device-list h3 {
    margin-bottom: 10px;
  }
  
  .device-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .device-list th, .device-list td {
    padding: 10px;
    
    text-align:left;
  }
  
  .device-list th {

    border-bottom: 1px solid #ddd;
  }
  
  .device-row {
    cursor: pointer;
  }
  
  .device-row:hover {
    background-color: #f0f0f0; /* Change to your desired hover color */
  }

  .button-group {
    display: flex;
    gap: 10px;
  }
  
  .delete-user-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 8px;
  }
  
  .delete-user-button:hover {
    background-color: #c82333;
  }
  
  .device-row:hover {
    background-color: #f0f0f0;
  }