.users-page {
  display: flex;
  width: 100%;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content {
  flex: 1;
  padding: 20px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px #ccc;
  margin-bottom: 20px;
}

.content-title {
  display: flex;
  align-items: center;
}

.content-icon {
  font-size: 24px;
  margin-right: 10px;
}

.add-user-button {
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width:150px;
}

.search-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px #ccc;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 10px 10px;
  border-radius: 8px;
  width: 50%;
}

.search-icon {
  margin-right: 10px;
}

.search-input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
}

.sort-dropdown {
  display: flex;
  align-items: center;
}

.sort-label {
  margin-right: 10px;
  font-size: 16px;
}

.sort-select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  appearance: none;
  background-color: #f2f2f2;
}

.table-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px #ccc;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
}

.users-table th {
  padding: 10px;
  text-align: left;
  background-color: #fff;
  border-bottom: 2px solid #ddd;
}

.users-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #f2f2f2;
}

.users-table tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.add-user-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.add-user-button:hover {
  background-color: #ff4e00;
}
