/* Wrapper Container */
.wrapper {
  display: flex;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent overflow */
  width: 100%;
}


/* Sidebar logo section */
.sidebar-logo {
  display: block;
  width: 100px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

/* Sidebar menu buttons section */
.sidebar-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align at the start */
  background-color: #fff;
  width: 100%;
  flex-grow: 1; /* Allow to grow */
}


.sidebar-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  flex-direction: column;
  transition: background-color 0.3s, color 0.3s;
}

.logo-image {
  width: 100%;
  height: auto;
}

/* Main Content */


.profile-picture {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%; /* Make it circular */
  object-fit: cover; /* Ensure the image covers the container without stretching */
  margin-right: 10px; /* Space between image and text */
}

.welcome {
  display: flex;
  align-items: center;
  height:50px;
}

.profile-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

strong {
  margin-left: 5px; /* Add space between "Hi!" and the user's name */
}

/* Container for Header and Content */
.content-container {
  display: flex;
  flex-direction: column;
  flex: 1; /* Take up remaining space */
  overflow: hidden; /* Prevent overflow */
  width: 100%;
}

/* Header */
.header {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px #ccc;
}





.sidebar {
  display: flex;
  flex-direction: column;
  width: 120px; /* Fixed width */
  background-color:#fff;


  border-right: 1px solid #ddd;
  align-items: center;

  box-shadow: 0 0 10px #ccc;

}


.logosidebar{
  display: block;
  width: 100px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top:1rem;
}

.sidebar-menu-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  color: #000;
  transition: background-color 0.3s, color 0.3s;
  height:100%;
  width:100%;
}

.sidebar-menu-items {
  cursor: pointer;
}


.sidebar-link.active {
  color: #FF5800;
  font-weight:bold;
}


.sidebar-logout {
  margin-bottom: 3rem !important;
  flex-direction: column;
  justify-content: flex-end;
  gap: .5rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  bottom:0;


padding-top:1rem;
padding-bottom:1rem;
  text-align: center;

  text-decoration: none;


  transition: background-color 0.3s, color 0.3s;

}

.sidebar-logout:hover, .sidebar-link:hover {
  background-color:#f0f0f0;
}

.sidebar-icon {
  font-size: 24px;
  margin-bottom: 5px;
}


.sidebar-bottom {
  border-top:#ddd 2px solid;
  width:100%;
}









