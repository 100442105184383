/* ConnectedDevice.css */
.connected-device-page {
  display: flex;
  width: 100%;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content {
  flex: 1;
  padding: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.connectionbutton {
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 150px;  
}

.connectionbutton:hover {
  background-color:#FF4E00;
}

.status-box, .device-name, .details, .parameters, .logs {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px #ccc;
}

.status-box {
  grid-column: 1 / 2;
  align-items: center;
  display: flex;
  justify-content: space-between; /* Ensures items are spaced out evenly */
  padding: 10px; /* Add padding for better visual spacing */
}

.status-box h2 {
  margin: 0;
  display: flex;
  align-items: center;
}

.connected-status-icon {
  font-size: 24px; /* Adjust the size as needed */
  margin-left: 5px; /* Add some space between the text and the icon */
}

.green-icon {
  color: #34CE74; /* Green color for check icon */
}

.red-icon {
  color: #ff0000; /* Red color for cross icon */
}

.device-name {
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.device-details {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.device-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
}

.device-detail span.value {
  font-weight: normal;
  margin-top: 5px;
}

.save-button {
  background-color: #000; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Get it to align properly */
  font-size: 16px; /* Increase font size */
  margin-top: 10px;
  cursor: pointer; /* Add a pointer on hover */
  border-radius: 8px; /* Rounded corners */
}

.save-button:hover {
  background-color: #FF5800; /* Darker green on hover */
}

.parameters {
  position: relative;
}

.save-button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
}

.details {
  grid-column: 1 / 2;
  padding-bottom: 10px;
}

.parameters-and-logs {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.parameters {
  padding-bottom: 10px;
  position: relative;
}

.details h3, .parameters h3, .features h3, .logs h3 {
  margin-top: 0;
  padding-bottom: 5px;
  border-bottom: 2px solid #ddd;
}

.details-info, .parameters-info, .features-info {
  display: flex;
  flex-direction: column;
}

.details-info span, .parameters-info span, .features-info span {
  display: block;
  margin-bottom: 10px;
}

.detail-item, .parameter-item, .feature-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.detail-label, .parameter-label, .feature-label {
  font-weight: bold;
  flex: 1;
}

.detail-value, .parameter-value, .feature-value, .parameter-select, .feature-select {
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px; /* Set a consistent width */
  border: none;
  text-align: right; /* Right-align the text */
  flex: 2;
}

.parameter-select, .feature-select {
  cursor: not-allowed;
  padding: 5px;
  text-align-last: right; /* Right-align the selected text */
}

.parameter-select.unlocked, .feature-select.unlocked {
  cursor: pointer;
  background-color: #fff;
}

.parameter-select option, .feature-select option {
  text-align: right; /* Right-align the options */
  padding-right: 10px; /* Add padding to the right */
}

.lock-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  color: #000;
  font-size: 20px;
}

.lock-icon.unlocked {
  color: #FF5800;
}

.logs {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px #ccc;
}

.logs-table {
  width: 100%;
  border-collapse: collapse;
}

.logs-table th, .logs-table td {
  padding: 10px;
  text-align: left;
}

.logs-table th {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0px;
}

.parameters-info {
  margin-bottom: 30px;
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.port-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.port-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.port-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.port-list li:hover {
  background-color: #f0f0f0;
}

.port-modal button {
  margin-top: 10px!important;
  display: block; /* Ensure the button takes up full width */
  width: fit-content; /* Set width to fit its content */
  margin: 0 auto; /* Center the button horizontally */

  text-align: center; /* Center the text horizontally */
}

.separator {
  border: 0;
  height: 1px;
  background: #ddd;
  margin: 20px 0;
}

.apply-changes-container {
  text-align: center;
}

.differences {
  margin: 10px 0;
}

.differences ul {
  list-style: none;
  padding: 0;
}