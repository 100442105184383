.login-page {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: center;
}

h1 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.login-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #000;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.login-button:hover {
  background-color:#ff4e00;
}

.recover-password {
  font-size: 12px;
  color: #000;
  text-decoration: none;
}

.recover-password:hover {
  text-decoration: underline;
}

.divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
  margin: 0 10px;
}

.divider span {
  color: #555;
}

.upload-log-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #000;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.upload-log-button:hover {
  background-color: #ff4e00;
}



.logo {
  display: block;
  margin: 0 auto 20px;
  max-width: 200px; /* Adjust as needed */
}

.error-message {
  color:red;
  font-weight:bold;
}